import { API } from 'aws-amplify'
export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED'
export const GET_USERS_RECEIVED = 'GET_USERS_RECEIVED'
export const GET_USERS_FAILED = 'GET_USERS_FAILED'
export const GET_USER = 'GET_USER'
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS'

export const GET_STUDIES_REQUESTED = 'GET_STUDIES_REQUESTED'
export const GET_STUDIES_RECEIVED = 'GET_STUDIES_RECEIVED'
export const GET_STUDIES_FAILED = 'GET_STUDIES_FAILED'

export const GET_INVITES_REQUESTED = 'GET_INVITES_REQUESTED'
export const GET_INVITES_RECEIVED = 'GET_INVITES_RECEIVED'
export const GET_INVITES_FAILED = 'GET_INVITES_FAILED'
export const DELETE_USER_INVITE = 'DELETE_USER_INVITE'

export const GET_USER_STUDIES_REQUESTED = 'GET_USER_STUDIES_REQUESTED'
export const GET_USER_STUDIES_RECEIVED = 'GET_USER_STUDIES_RECEIVED'
export const GET_USER_STUDIES_FAILED = 'GET_USER_STUDIES_FAILED'

export const DELETE_INVITE = 'DELETE_INVITE'
export const DELETE_INVITE_FAILED = 'DELETE_INVITE_FAILED'

export const GET_STUDY_PERMISSION_REQUESTED = 'GET_STUDY_PERMISSION_REQUESTED'
export const GET_STUDY_PERMISSION_RECEIVED  = 'GET_STUDY_PERMISSION_RECEIVED'
export const GET_STUDY_PERMISSION_FAILED = 'GET_STUDY_PERMISSION_FAILED'
export const SET_STUDY_PERMISSION = 'SET_STUDY_PERMISSION'
export const SAVE_STUDY_PERMISSONS_SUCCESS = 'SAVE_STUDY_PERMISSONS_SUCCESS'
export const SAVE_STUDY_PERMISSONS_FAILED = 'SAVE_STUDY_PERMISSONS_FAILED'
export const GET_STUDY = 'GET_STUDY'

const apiName = 'MetawebserviceCore';

/*
API routes used
  AdminGetUsers
  AdminGetStudies
  AdminGetInvitations
  AdminFindUserStudies
  AdminDeleteInvite
  AdminAssignStudyPermissions GET
  AdminAssignStudyPermissions POST
*/


export const getUsersAction = () => {
    
    return function(dispatch) {
      dispatch({
        type: GET_USERS_REQUESTED,
    });     
    
    const path = '/Admin/AdminGetUsers';
    
    API.get(apiName, path).then(response => {
        
        dispatch({
            type: GET_USERS_RECEIVED,
            payload: response
        })
    }).catch(error => {
        console.log(error)        
        dispatch({
            type: GET_USERS_FAILED,
            payload: error.response
        })
    });    
    }
}

export const getUserAction = (id) => {
    return (dispatch, getState) => {
        const state = getState()
      
        const user = state.users.filter(s => s.customerId === Number(id))[0]
        
        dispatch({
            type: GET_USER,
            payload: user
        })
    }
};

export const setLoginStatusAction = (status) => {
    return (dispatch) => {
                
        dispatch({
            type: SET_LOGIN_STATUS,
            payload: status
        })
    }
};

export const getStudyAction = (id) => {
    return (dispatch, getState) => {
        const state = getState()
                
        const study = (state.studies.length !== 0) ? 
            state.studies.filter(s => s.studyId === Number(id))[0]
            :
            state.selectedUser.studies.filter(s => s.studyId === Number(id))[0]

                
        dispatch({
            type: GET_STUDY,
            payload: study
        })
    }
};

export const getStudiesAction = () => {
    
    return function(dispatch) {
      dispatch({
        type: GET_STUDIES_REQUESTED,
    });     
    
    const path = '/Admin/AdminGetStudies';
        
    API.get(apiName, path).then(response => {
        dispatch({
            type: GET_STUDIES_RECEIVED,
            payload: response
        })
    }).catch(error => {
        console.log(error)        
        dispatch({
            type: GET_STUDIES_FAILED,
            payload: error.response
        })
    });    
    }
}

export const getUserInvitationsAction = (email) => {

    return function(dispatch) {
        dispatch({
          type: GET_INVITES_REQUESTED,
    });     
      
      const path = 'Admin/AdminGetInvitations';
      
      API.get(apiName, path, {
        'queryStringParameters': {
          'email': email
        }
      }).then(response => {
          dispatch({
              type: GET_INVITES_RECEIVED,
              payload: response
          })
      }).catch(error => {
          console.log(error)        
          dispatch({
              type: GET_INVITES_FAILED,
              payload: error.response
          })
      });    
      }      
};

export const getUserStudiesAction = (id) => {

    return function(dispatch) {
      dispatch({
        type: GET_USER_STUDIES_REQUESTED,
    });     
    
    const path = '/Admin/AdminFindUserStudies';

    API.get(apiName, path, {
        'queryStringParameters': {
          'CustomerId': id
    }})    
    .then(response => {
        dispatch({
            type: GET_USER_STUDIES_RECEIVED,
            payload: response
        })
    }).catch(error => {
        console.log(error)        
        dispatch({
            type: GET_USER_STUDIES_FAILED,
            payload: error.response
        })
    });    
    }
}

export const deleteInviteAction = (id) => {

    return function(dispatch) {    

      const path = '/Admin/AdminDeleteInvite';
      
      API.put(apiName, path, {
        'queryStringParameters': {
          'InvitationId': id
        }
      }).then(response => {
          dispatch({
              type: DELETE_INVITE,
              payload: response
          })
      }).catch(error => {
          console.log(error)        
          dispatch({
              type: DELETE_INVITE_FAILED,
              payload: error.response
          })
      });    
      }      
};

export const getStudyPermissionsAction = (userid, studyid) => {
    
    return function(dispatch) {
      dispatch({
        type: GET_STUDY_PERMISSION_REQUESTED,
    });     
    
    const path = '/Admin/AdminAssignStudyPermissions/'+studyid+'/'+userid;

    API.get(apiName, path)    
    .then(response => {
        dispatch({
            type: GET_STUDY_PERMISSION_RECEIVED,
            payload: response
        })
    }).catch(error => {
        console.log(error)        
        dispatch({
            type: GET_STUDY_PERMISSION_FAILED,
            payload: error.response
        })
    });    
    }



}

export const setStudyPermissionAction = (perm, value) => {
   return (dispatch, getState) => {
        const state = getState() 

        let permissions = state.selectedStudy.permissions
        const objIndex = permissions.findIndex((obj => obj.permission === perm));
        permissions[objIndex].selected = value
        
        dispatch({
            type: SET_STUDY_PERMISSION,
            payload: permissions
        })
    }    
}

export const saveStudyPermissionsAction = () => {
    
    return (dispatch, getState) => {
        const state = getState()
        
        const params = {
            AssignToUserId: state.selectedUser.user.customerId,
            StudyId: state.selectedStudy.study.studyId,
            permissions: state.selectedStudy.permissions
        };

        const path = '/Admin/AdminAssignStudyPermissions';   
 
        let myInit = {
            body: params
        }
        
        API.post(apiName, path, myInit)
        .then(response => {

            dispatch({
                type: SAVE_STUDY_PERMISSONS_SUCCESS,
                payload: response
            })

        }).catch(error => {
            console.log(error)

            dispatch({
                type: SAVE_STUDY_PERMISSONS_FAILED,
                payload: error.response
            })
        });          
    }
}