import React, { Component } from 'react'
import Amplify, { Auth } from 'aws-amplify'
import { Route, Switch } from 'react-router-dom';
import {
  IonApp,
  IonPage
} from '@ionic/react'
import UsersPage from './Pages/UsersPage';
import UserDetailPage from './Pages/UserDetailPage'
import StudyDetailPage from './Pages/StudyDetailPage';
import { withAuthenticator } from 'aws-amplify-react';

// Import Ionic 5 styles
import '@ionic/core/css/core.css'
import '@ionic/core/css/ionic.bundle.css'
import '@aws-amplify/ui/dist/style.css'
import './App.css'

const configuration =
{
  test: {
    Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: 'eu-west-1:c3fe4e21-2e92-4dad-a295-5852d24e2495',
      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_syn8kSgl2',
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: '4s37jnbikn1s7jm5e05212rjp6',
    },

    API: {
      endpoints: [
        {
          name: "MetawebserviceCore",
          endpoint: "https://testapi.metawebservice.com/test",
          custom_header: async () => {
            //return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
            return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }

          }
        }
      ]
    }
  },
  dev: {
    Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: 'eu-west-1:9eb11c60-23ab-48b1-892c-196bd7577a3d',
      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_jmS0VsTuM',
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: '4jun66qhtkbm1gr3parou7gnh6',
    },

    API: {
      endpoints: [
        {
          name: "MetawebserviceCore",
          endpoint: "https://devapi.metawebservice.com/dev",
          custom_header: async () => {
            //return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
            return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }

          }
        },
        {
          name: "MetawebserviceReader",
          endpoint: "https://devapi.metawebservice.com/dev",
          custom_header: async () => {
            //return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
            return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }

          }
        },
      ]
    }
  },
  prod: {
    Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: 'eu-west-1:1977e1ff-16af-468e-832a-d66f4edbc3f8',
      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_rVfnewP8u',
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: '6oktuoa2jd0jtdn72u97uvfpg0',
    },

    API: {
      endpoints: [
        {
          name: "MetawebserviceCore",
          endpoint: "https://api.metawebservice.com/prod",
          custom_header: async () => {
            //return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
            return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }

          }
        }
      ]
    }
  }
}

Amplify.configure(configuration["prod"])

class App extends Component {

  async componentDidMount() {

    try {
      await Auth.currentSession();
      this.props.history.push("/users");
    }
    catch (e) {
      console.log(e);
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <div className="App">

        <IonApp>
          <IonPage>
            <Switch>
              <Route exact path="/users" component={props => <UsersPage {...props} />} />
              <Route path="/user/:userid" exact component={props => <UserDetailPage {...props} />} />
              <Route path="/user/:userid/:studyid" exact component={props => <StudyDetailPage {...props} />} />
            </Switch>
          </IonPage>
        </IonApp>
      </div>
    );
  }
}

export default withAuthenticator(App)
