import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { getStudyPermissionsAction, getStudyAction, setStudyPermissionAction, saveStudyPermissionsAction } from '../redux/actions';
import {
    IonContent,
    IonToggle,
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonCardTitle,
    IonCardSubtitle,
    IonNote,
    IonProgressBar,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonItemDivider
  } from '@ionic/react'
  import { chevronBack, refreshCircle} from 'ionicons/icons';

class StudyDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = { showSegment: 'Studies' };        
    }

    async componentDidMount() {
        const { history } = this.props;
        const location = history.location;
        const userid = location['pathname'].split("/")[2]
        const studyid = location['pathname'].split("/")[3]
        await this.props.getStudyAction(studyid)
        await this.props.getStudyPermissionsAction(userid,studyid);        
    }
    
    async componentDidUpdate(prevProps) {
        const { selectedStudy, history } = this.props;
        const location = history.location;
        const studyid = location['pathname'].split("/")[3]
        const previousUser = prevProps.selectedStudy;

        //Typical usage, don't forget to compare the props
        if ((selectedStudy.study !== previousUser.study && selectedStudy.study !== undefined) ||  (selectedStudy.study === undefined)) {
            await this.props.getStudyAction(studyid)
        }
    }
    
    refresh = async () => {
        const { selectedUser, selectedStudy } = this.props;
        if (selectedUser.user !== undefined && selectedStudy.study !== undefined) {
            await this.props.getStudyAction(selectedStudy.study.studyId)
            await this.props.getStudyPermissionsAction(selectedUser.user.customerId, selectedStudy.study.studyId);
        }        
    }

    setChecked = async (perm, value) => {
        await this.props.setStudyPermissionAction(perm, value)
    }
    
    savePermissions = async () => {
        const { history } = this.props;
        await this.props.saveStudyPermissionsAction()
        history.goBack()
    }
   
    render() {
        const { history, selectedStudy, selectedUser, loading } = this.props;        
        return (
            <>
            <IonHeader translucent>
              <IonToolbar>
                <IonButtons slot="start">
                    <IonButton onclick={(e) => {e.preventDefault(); history.goBack()}}>
                        <IonIcon slot="start" icon={chevronBack} /> Back
                </IonButton>                    
                </IonButtons>
                <IonButtons slot="end">
                <IonButton onclick={(e) => {e.preventDefault(); this.refresh()}}>
                        <IonIcon slot="end" icon={refreshCircle} />
                    </IonButton>
                </IonButtons>                
              </IonToolbar>
            </IonHeader>                          
            <IonCardTitle>{(selectedUser.user) ? selectedUser.user.contactName : ""}</IonCardTitle>
            <IonCardSubtitle>{(selectedUser.user) ? selectedUser.user.cognitoUserId : ""}</IonCardSubtitle>
            <IonCardSubtitle>{(selectedUser.user) ? selectedUser.user.email : ""}</IonCardSubtitle>
            <IonNote></IonNote><br/>
            <IonItemDivider>
                <IonLabel>
                    <h2>{(selectedStudy.study) ? selectedStudy.study.studyName : ""}</h2>    
                </IonLabel>
            </IonItemDivider>            
            <IonContent>
            {(loading) && (<IonProgressBar type="indeterminate"></IonProgressBar>)}
            <IonList>
                {selectedStudy.permissions.map((permission, i) => {
                    return (
                    <IonItem key={i}>
                        <IonLabel>{permission.permission}</IonLabel>
                        <IonToggle checked={permission.selected} onIonChange={e => this.setChecked(permission.permission, e.detail.checked)} />
                    </IonItem>
                    )
                })
                }
            </IonList>
            <IonButton expand="block" fill="solid" color="success" onClick={() => this.savePermissions()}>Save</IonButton>
          </IonContent>
          </>
        );            
    }
}

StudyDetailPage.propTypes = {
    studies: PropTypes.array,
    selectedUser: PropTypes.object.isRequired,
    selectedStudy: PropTypes.object.isRequired,
    getStudyAction: PropTypes.func,
    getStudyPermissionAction: PropTypes.func,
    setPermission: PropTypes.func
}

const mapStateToProps = state => {
    return {
        selectedUser: state.selectedUser,
        selectedStudy: state.selectedStudy,
        studies: state.studies
    }
}

const mapDispatchToProps = (dispatch) => ({        
    getStudyPermissionsAction: (userid, studyid) => dispatch(getStudyPermissionsAction(userid, studyid)),
    getStudyAction: (studyid) => dispatch(getStudyAction(studyid)),
    setStudyPermissionAction: (perm, value) => dispatch(setStudyPermissionAction(perm, value)),
    saveStudyPermissionsAction: () => dispatch(saveStudyPermissionsAction())    
})

export default connect(mapStateToProps, mapDispatchToProps)(StudyDetailPage)