import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { getStudiesAction, getUserAction, getUserInvitationsAction, getUserStudiesAction, deleteInviteAction } from '../redux/actions';
import {
    IonNote,
    IonContent,
    IonSegment,
    IonCardSubtitle,
    IonCardTitle,
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonItemDivider,
    IonSegmentButton,
    IonRefresher, 
    IonRefresherContent,
    /*IonPicker,*/
    IonProgressBar,
    IonHeader,
    IonButtons,
    IonToolbar,
    IonSelect,
    IonSelectOption
  } from '@ionic/react'
  import { chevronBack, refreshCircle,  addOutline, trashOutline, checkmarkCircleOutline} from 'ionicons/icons';

class UserDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = { showSegment: 'Studies' /*, isOpen: false*/ };        
    }

    async componentDidMount() {
        const { history } = this.props;
        const location = history.location;
        const id = location['pathname'].split("/")[2]
        await this.props.getUserAction(id);
        await this.props.getStudiesAction();    
    }

    async componentDidUpdate(prevProps) {
        const { selectedUser } = this.props;
        const previousUser = prevProps.selectedUser;
        console.log(previousUser)
        console.log(selectedUser)
        //Typical usage, don't forget to compare the props

        if (selectedUser.user !== previousUser.user && selectedUser.user !== undefined) {
            await this.props.getUserInvitationsAction(selectedUser.user.email)
            await this.props.getUserStudiesAction(selectedUser.user.customerId)
        }
    }

    setSegment = (value) => {
        this.setState({
            showSegment: value
        })
    }

    deleteInvite = async (id) => {        
        const { selectedUser } = this.props;
        await this.props.deleteInviteAction(id);
        if (selectedUser !== undefined) {
            await this.props.getUserInvitationsAction(selectedUser.user.email)
            await this.props.getUserStudiesAction(selectedUser.user.customerId)
        }
    }

    refresh = async () => {
        const { selectedUser } = this.props;
        if (selectedUser !== undefined) {
            await this.props.getUserInvitationsAction(selectedUser.user.email)
            await this.props.getUserStudiesAction(selectedUser.user.customerId)
        }        
    }

    doRefresh = async (event) => {
        const { selectedUser } = this.props;
        if (selectedUser !== undefined) {
            await this.props.getUserInvitationsAction(selectedUser.user.email)
            await this.props.getUserStudiesAction(selectedUser.user.customerId)
            event.detail.complete()
        }
        event.detail.complete()
    }    
     
    openPicker = async (value) => {        
        this.setState({
            isOpen: value
        })        
    }

    StudySelected = (value) => {
        const { history, selectedUser } = this.props;
        //history.push('/user/'+ selectedUser.user.customerId + "/" + value["STUDY"].value)
        history.push('/user/'+ selectedUser.user.customerId + "/" + value)
    }

    render() {
        const {selectedUser, history, studies, loading } = this.props;
        /*const { isOpen } = this.state;

        const StudyColumn = {
            name: "STUDY",
            options: studies.map(study => {
                return { text: study.studyName, value: study.studyId }
            })
        }*/

        return (
            <>        
            <IonHeader translucent>
              <IonToolbar>
                <IonButtons slot="start">
                    <IonButton onclick={(e) => {e.preventDefault(); history.goBack()}}>
                        <IonIcon slot="start" icon={chevronBack} /> Back
                    </IonButton>
                </IonButtons>
                <IonButtons slot="end">
                <IonButton onclick={(e) => {e.preventDefault(); this.refresh()}}>
                        <IonIcon slot="end" icon={refreshCircle} />
                    </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonCardTitle>{(selectedUser.user) ? selectedUser.user.contactName : ""}</IonCardTitle>
            <IonCardSubtitle>{(selectedUser.user) ? selectedUser.user.cognitoUserId : ""}</IonCardSubtitle>
            <IonCardSubtitle>{(selectedUser.user) ? selectedUser.user.email : ""}</IonCardSubtitle>
            <IonNote></IonNote><br />
            <IonSegment value={this.state.showSegment} onIonChange={e => this.setSegment(e.detail.value)}>
                <IonSegmentButton value="Studies">
                    <IonLabel>Studies</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="Invites">
                    <IonLabel>Invites</IonLabel>
                </IonSegmentButton>
            </IonSegment>
            <IonItemDivider>
                <IonLabel>
                    
                </IonLabel>
            </IonItemDivider>
            <IonContent>
            <IonRefresher slot="fixed" onIonRefresh={this.doRefresh}>
            <IonRefresherContent></IonRefresherContent>
            </IonRefresher>                
            {this.state.showSegment === "Invites" ?
              selectedUser.invitations.length === 0 ? (
                  <>
                     <IonLabel>
                         No Invitations                         
                     </IonLabel>
                         <br/>
                     <IonIcon size="large" color="success" icon={checkmarkCircleOutline} />
                </>
                     
              )
              :
              (
                <>
                {(loading) && (<IonProgressBar type="indeterminate"></IonProgressBar>)}
                <IonList>
                    {selectedUser.invitations.map((invite, i) => {
                        return (
                            <IonItem key={i}>
                                <IonLabel>
                                    <h2>{(invite.study) ? "Study: " + invite.study.studyName : ""}</h2>
                                    <p>{(invite.studyUserInvitationPermissions) ? "Permissions: " + invite.studyUserInvitationPermissions.map(p => { return p.permissionId}).join(", ") : ""}</p>
                                </IonLabel>
                                <IonButton size="large" slot="end" color="danger" onclick={(e) => {e.preventDefault(); this.deleteInvite(invite.studyUserInvitationId)}}>
                                    <IonIcon  icon={trashOutline} />
                                </IonButton>                                                                
                            </IonItem>
                        );
                    })}
                </IonList>
                </>
                )            
            :
                selectedUser.studies.length === 0 ? (
                    <>
                    <IonLabel>No studies.</IonLabel>
                    <IonList>
                        <IonItem color="success">
                            <IonIcon slot="start" icon={addOutline} />
                            <IonLabel>Add Study</IonLabel>
                            <IonSelect ok-text="Select" cancel-text="Cancal" onIonChange={e => this.StudySelected(e.detail.value)}>>
                                {studies.map(study => {
                                    return (
                                        <IonSelectOption key={study.studyId} value={study.studyId}>{study.studyName}</IonSelectOption>
                                    )
                                })}
                            </IonSelect>                            
                        </IonItem>
                    </IonList>
                            {/*<IonButton expand="block" fill="solid" color="success" onClick={() => this.openPicker(true)}>ADD STUDY</IonButton>*/}
                    </>
                )
                :
                (
                    <>
                    {(loading) && (<IonProgressBar type="indeterminate"></IonProgressBar>)}
                    <IonList>
                        {selectedUser.studies.map((study, i) => {
                            return (
                                <IonItem key={i} button
                                    onclick={(e) => {
                                    e.preventDefault();
                                    history.push('/user/'+ selectedUser.user.customerId + "/" + study.studyId)}}>
                                    <IonLabel>
                                        <h2>{study.studyName}</h2>
                                        <p>{study.studyProject}</p>
                                    </IonLabel>
                                </IonItem>
                            );
                        })}
                        <IonItem color="success">
                            <IonIcon slot="start" icon={addOutline} />
                            <IonLabel>Add Study</IonLabel>
                            <IonSelect ok-text="Select" cancel-text="Cancal" onIonChange={e => this.StudySelected(e.detail.value)}>>
                                {studies.map(study => {
                                    return (
                                        <IonSelectOption key={study.studyId} value={study.studyId}>{study.studyName}</IonSelectOption>
                                    )
                                })}
                            </IonSelect>                            
                        </IonItem>
                    </IonList>
                    {/*<IonButton expand="block" fill="solid" color="success" onClick={() => this.openPicker(true)}>ADD STUDY</IonButton>*/}
                    </>
                )
            }
            {/*<IonPicke
                isOpen={isOpen}
                columns={[StudyColumn]}
                buttons={[
                {
                    text: "Cancel",
                    role: "cancel",
                    handler: value => {
                        this.openPicker(false)
                    }
                },
                {
                text: "Confirm",
                    handler: value => {
                        this.StudySelected(value)
                }
            }
      ]}
    ></IonPicker>*/}                            
          </IonContent>
          </>
        );     
        
    }
}

UserDetailPage.propTypes = {
    studies: PropTypes.array,
    selectedUser: PropTypes.object.isRequired,
    getStudiesAction: PropTypes.func,
    getUserAction: PropTypes.func,
    getUserInvitationsAction: PropTypes.func,
    getUserStudiesAction: PropTypes.func,
    deleteInviteAction: PropTypes.func
}

const mapStateToProps = state => {
    return {
        selectedUser: state.selectedUser,
        studies: state.studies
    }
}

const mapDispatchToProps = (dispatch) => ({        
    getStudiesAction: () => dispatch(getStudiesAction()),
    getUserAction: (id) => dispatch(getUserAction(id)),    
    getUserInvitationsAction: (email) => dispatch(getUserInvitationsAction(email)),
    getUserStudiesAction: (id) => dispatch(getUserStudiesAction(id)),
    deleteInviteAction: (id) => dispatch(deleteInviteAction(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailPage)