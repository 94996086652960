import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types';
import { getUsersAction, setLoginStatusAction } from '../redux/actions';
import { Auth } from 'aws-amplify';
import {
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonSkeletonText,
    IonRefresher, 
    IonRefresherContent,
    IonSearchbar,
    IonListHeader,
    IonButton,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonItemDivider
  } from '@ionic/react'
import './UsersPage.css'  
import { exit } from 'ionicons/icons';


class UsersPage extends Component {
  constructor(props) {
    super(props);
    this.state = { searchText: '' };        
  }

  async componentDidMount() {
    if (this.props.users.length === 0) {
      this.props.getUsersAction()
    }        
  }

  handleLogout = async () => {
    this.props.setLoginStatusAction(false)
    await Auth.signOut();
    this.props.history.push("/login");
  }

    doRefresh = async (event) => {
      await this.props.getUsersAction()
      event.detail.complete()
    }

    setSearchText = async (value) => {
      this.setState({ searchText: value})
    }

    render() {
        let {history, users } = this.props;
        const { searchText } = this.state;
        
        if (searchText !== "") {
          users = users.filter(item => item.email.toLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1);
        }

        return (
            <>
            <IonHeader translucent>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton style={{position: "absolute", top: 0, right: 0}} onclick={(e) => {e.preventDefault(); this.handleLogout()}}>
                      <IonIcon slot="end" icon={exit} />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
              <IonListHeader>
              <IonLabel>Users<p>by latest login</p></IonLabel>              
            </IonListHeader>
            </IonHeader>
            <IonSearchbar value={searchText} onIonChange={e => this.setSearchText(e.detail.value)}></IonSearchbar>  
            <IonContent>                      
            <IonRefresher slot="fixed" onIonRefresh={this.doRefresh}>
            <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            <IonItemDivider>
                <IonLabel>
                    
                </IonLabel>
            </IonItemDivider>            
                {users.length !== 0 ? (
                <IonList> 
                        {users.map((user, i) => {
                            return (
                                <IonItem key={i} button
                                         onclick={(e) => {
                                            e.preventDefault();
                                            history.push('/user/'+user.customerId)}}>
                                    <IonLabel>
                                        <h2>{user.contactName}</h2>
                                        <p>{user.email}</p>
                                        <p>{user.company}</p>
                                    </IonLabel>
                                </IonItem>
                            );
                        })}
                    </IonList>
                    ) :
                    (
                        <>

                        <IonList>
                          <IonItem>
                            <IonLabel>
                              <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                              </h3>
                              <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                              </p>
                              <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                              </p>
                            </IonLabel>
                          </IonItem>
                          <IonItem>
                            <IonLabel>
                              <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                              </h3>
                              <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                              </p>
                              <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                              </p>
                            </IonLabel>
                          </IonItem>
                          <IonItem>
                            <IonLabel>
                              <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                              </h3>
                              <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                              </p>
                              <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                              </p>
                            </IonLabel>
                          </IonItem>
                          <IonItem>
                            <IonLabel>
                              <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                              </h3>
                              <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                              </p>
                              <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                              </p>
                            </IonLabel>
                          </IonItem>
                          <IonItem>
                            <IonLabel>
                              <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                              </h3>
                              <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                              </p>
                              <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                              </p>
                            </IonLabel>
                          </IonItem>
                          <IonItem>
                            <IonLabel>
                              <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                              </h3>
                              <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                              </p>
                              <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                              </p>
                            </IonLabel>
                          </IonItem>
                          <IonItem>
                            <IonLabel>
                              <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                              </h3>
                              <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                              </p>
                              <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                              </p>
                            </IonLabel>
                          </IonItem>
                          <IonItem>
                            <IonLabel>
                              <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                              </h3>
                              <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                              </p>
                              <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                              </p>
                            </IonLabel>
                          </IonItem>
                          <IonItem>
                            <IonLabel>
                              <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                              </h3>
                              <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                              </p>
                              <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                              </p>
                            </IonLabel>
                          </IonItem>
                          <IonItem>
                            <IonLabel>
                              <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                              </h3>
                              <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                              </p>
                              <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                              </p>
                            </IonLabel>
                          </IonItem>                                                                                                                                                                                 
                        </IonList>
                      </>
                    )
                }             
          </IonContent>                   
          </>
        );        
    }
}

UsersPage.propTypes = {
    users: PropTypes.array.isRequired,
    usersApiError: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    getUsersAction: PropTypes.func
}

const mapStateToProps = state => {
    return {
        loggedIn: state.loggedIn,
        users: state.users,
        usersApiError: state.usersApiError,
        loading: state.loading  
    }
}

const mapDispatchToProps = dispatch => ({
    getUsersAction: () => dispatch(getUsersAction()),
    setLoginStatusAction: (value) => dispatch(setLoginStatusAction(value))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps), 
)(UsersPage);

//export default connect(mapStateToProps, mapDispatchToProps)(UsersPage)


