import * as actions from './actions';

const initialState = {
    loggedIn: false,
    loading: false,
    users: [],
    usersApiError: "",
    studies: [],
    studiesApiError: "",
    selectedStudy: {
        study: {},
        permissions: [],
        ApiError: ""
    },
    selectedUser: {
        user: {},
        studies: [],        
        invitations: [],
        ApiError: ""
    },
    selectedInvite: {}
}

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_USERS_REQUESTED:
            console.log("GET_USERS_REQUESTED")
            return {
                ...state,
                loading: true
            };
        case actions.GET_USERS_RECEIVED:
            console.log("GET_USERS_RECEIVED")

            const users = action.payload.sort((a,b)=>new Date(b.loginDate)- new Date(a.loginDate));

            return {
                ...state,
                loading: false,
                users: users
            };
        case actions.GET_USERS_FAILED:
            console.log("GET_USERS_FAILED")
            return {
                ...state,
                loading: false,
                users: [],
                usersApiError: action.payload
            };
        case actions.GET_USER:
            console.log("GET_USER")
            return {
                ...state,
                selectedUser: {
                  ...state.selectedUser,
                  user: action.payload
                }                
            }; 
        case actions.SET_LOGIN_STATUS:
            console.log("SET_LOGIN_STATUS")
            return {
                ...state,
                loggedIn: action.payload
            };             
        case actions.GET_STUDY:
            console.log("GET_STUDY")
            return {
                ...state,
                selectedStudy: {
                  ...state.selectedStudy,
                  study: action.payload
                }                
            };             
            case actions.GET_STUDIES_REQUESTED:
                console.log("GET_STUDIES_REQUESTED")
                return {
                    ...state,
                    loading: true
                };
            case actions.GET_STUDIES_RECEIVED:
                console.log("GET_STUDIES_RECEIVED")
                return {
                    ...state,
                    loading: false,
                    studies: (action.payload !== null) ? action.payload : []
                };
            case actions.GET_STUDIES_FAILED:
                console.log("GET_STUDIES_FAILED")
                return {
                    ...state,
                    loading: false,
                    studies: [],
                    studiesApiError: action.payload
                };              
                
                
            case actions.GET_INVITES_REQUESTED:
                console.log("GET_INVITES_REQUESTED")
                return {
                    ...state,
                    loading: true
                };
            case actions.GET_INVITES_RECEIVED:
                console.log("GET_INVITES_RECEIVED")
                return {
                    ...state,
                    loading: false,
                    selectedUser: {
                        ...state.selectedUser,
                        invitations: (action.payload !== null) ? action.payload : []
                      }                        
                };
            case actions.GET_INVITES_FAILED:
                console.log("GET_INVITES_FAILED")
                return {
                    ...state,
                    loading: false,
                    selectedUser: {
                        ...state.selectedUser,
                        ApiError: action.payload,
                        invitations: []
                      }  
                };                
            case actions.GET_USER_STUDIES_REQUESTED:
                console.log("GET_USER_STUDIES_REQUESTED")
                return {
                    ...state,
                    loading: true
                };
            case actions.GET_USER_STUDIES_RECEIVED:
                console.log("GET_USER_STUDIES_RECEIVED")
                return {
                    ...state,
                    loading: false,
                    selectedUser: {
                        ...state.selectedUser,
                        studies: (action.payload !== null) ? action.payload : []
                        }                        
                };
            case actions.GET_USER_STUDIES_FAILED:
                console.log("GET_USER_STUDIES_FAILED")
                return {
                    ...state,
                    loading: false,
                    selectedUser: {
                        ...state.selectedUser,
                        ApiError: action.payload,
                        studies: []
                        }  
                };

                case actions.DELETE_INVITE:
                    console.log("DELETE_INVITE")
                    return {...state}
                
                case actions.DELETE_INVITE_FAILED:
                    console.log("DELETE_INVITE_FAILED")
                    return {
                        ...state,
                        loading: false,
                        selectedUser: {
                            ...state.selectedUser,
                            ApiError: action.payload
                            }  
                    };          

                    case actions.GET_STUDY_PERMISSION_REQUESTED:
                        console.log("GET_STUDY_PERMISSION_REQUESTED")
                        return {
                            ...state,
                            loading: true
                        };
                    case actions.GET_STUDY_PERMISSION_RECEIVED:
                        console.log("GET_STUDY_PERMISSION_RECEIVED")
                        return {
                            ...state,
                            loading: false,
                            selectedStudy: {
                                ...state.selectedStudy,
                                permissions: (action.payload !== null) ? action.payload.permissions : []
                            }                        
                        };
                    case actions.GET_STUDY_PERMISSION_FAILED:
                        console.log("GET_STUDY_PERMISSION_FAILED")
                        return {
                            ...state,
                            loading: false,
                            selectedStudy: {
                                ...state.selectedStudy,
                                ApiError: action.payload,
                                permissions: []
                                }  
                        };
                    case actions.SET_STUDY_PERMISSION:
                        console.log("SET_STUDY_PERMISSION")
                        return {
                            ...state,
                            selectedStudy: {
                                ...state.selectedStudy,
                                permissions: action.payload
                            }                        
                        };                       
        default:
            return {...state}
        }
}

