import React from 'react';
import { render} from 'react-dom';
import configureStore from './redux/store';

import './index.css';
import Root from './root'
import * as serviceWorker from './serviceWorker';

const store = configureStore();
function startApp() {
  render(
    <Root store={store} />,
    document.getElementById('root')
  )
}

function onDeviceReady() {
  /*
	if (navigator.splashscreen) {
		setTimeout(function () {
			navigator.splashscreen.hide();
		}, 1000);
	}

	if (typeof window.StatusBar !== 'undefined') {
		window.StatusBar.hide();
		window.StatusBar.show();
		window.StatusBar.styleDefault();
		window.StatusBar.overlaysWebView(false);
		window.StatusBar.backgroundColorByHexString('#ffffff');
	}
  */
	startApp();
}
if (typeof cordova === 'undefined') {
	startApp();
} else {
	document.addEventListener('deviceready', onDeviceReady, false);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
